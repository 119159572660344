import getDevUrl from 'App/Root/url'

export default {
  main: {
    useSubscriptions: true,
    urls: {
      local: 'http://localhost:3000',
      dev: getDevUrl,
      prod: 'https://api.getjusto.com'
    }
  },
  example: {
    useSubscriptions: false,
    urls: {
      local: 'http://localhost:4100',
      dev: 'https://example-orionjs.service.bejusto.com',
      prod: 'https://example-orionjs.service.getjusto.com'
    }
  },
  buckets: {
    useSubscriptions: false,
    urls: {
      local: 'http://localhost:4106',
      dev: 'https://buckets.service.bejusto.com',
      prod: 'https://buckets.service.getjusto.com'
    }
  },
  marketing: {
    useSubscriptions: false,
    urls: {
      local: 'http://localhost:4005',
      dev: 'https://marketing.service.bejusto.com',
      prod: 'https://marketing.service.getjusto.com'
    }
  },
  auth: {
    useSubscriptions: false,
    urls: {
      local: 'http://localhost:4112',
      dev: 'https://auth.service.bejusto.com',
      prod: 'https://auth.service.getjusto.com'
    }
  },
  finances: {
    omitAuth: false,
    useSubscriptions: false,
    urls: {
      local: 'http://localhost:4105',
      dev: 'https://finances.service.bejusto.com',
      prod: 'https://finances.service.getjusto.com'
    }
  },
  experiments: {
    useSubscriptions: false,
    urls: {
      local: 'http://localhost:4114',
      dev: 'https://experiments.service.bejusto.com',
      prod: 'https://experiments.service.getjusto.com'
    }
  },
  data: {
    useSubscriptions: false,
    urls: {
      local: 'http://localhost:4107',
      dev: 'https://data.service.bejusto.com',
      prod: 'https://data.service.getjusto.com'
    }
  }
}
